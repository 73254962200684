import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import myInstall from './common/myInstall'

import dayjs from "dayjs"

Vue.use(myInstall)

Vue.config.productionTip = false
Vue.prototype.dayjs = dayjs

router.beforeEach((to, from , next) => {
  if(to.meta.title){
    document.title = 'IGS.net - '+ to.meta.title;
  }
  next(); 
});

new Vue({
  router,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
