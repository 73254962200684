<template>
<v-row dense>
  <v-col 
    cols="12"
    v-for="item in reminDetail"
    :key="item.prj_id"
  >
    <detail-comp :detailData="item" :goDetail="false" :openId="openId"></detail-comp>
  </v-col>
</v-row>
</template>

<script>
  import DetailComp from "../components/DetailComp";
  export default {
    name: 'ReminDetail',
    components: {
      DetailComp
    },
    data () {
      return {
        openId:'',
        prj_id:'',
        cfmNo:'',
        reminDetail:[],
        demoDate: []
      }
    },
    methods: {
     getReminData:async function(){
        let params = '';
        if(this.prj_id) params = 'prjId=' + this.prj_id;
        if(this.cfmNo) params = 'cfmNoDtl=' + this.cfmNo;
       
        let rtnObj = await this.$aService.pfmWxMsg(this.openId,params,'');
        let proceRtn = [];
        for(let i = 0; i<rtnObj.length; i++){
          let obj = rtnObj[i];
          obj.eventLog = [];
          let findIdx = proceRtn.findIndex(item => item.prj_id === obj.prj_id);
          if(findIdx === -1){
            let addIdx = proceRtn.push({
              prj_id:obj.prj_id,
              cfm_no:obj.cfm_no,
              prod_no:obj.prod_no,
              sub_po_no:obj.sub_po_no,
              prjName:obj.prjName,
              data:[]
            });
            proceRtn[addIdx-1].data.push(obj);
          }else{
            proceRtn[findIdx].data.push(obj)
          }
        }

        this.reminDetail = proceRtn
     },
     getEevntLog: async function(obj){
      let params = 'eventLog='+obj.event_seq+'@'+obj.prj_id;
      let rtnObj = await this.$aService.pfmWxMsg(this.openId,params,'');
      obj.eventLog = rtnObj
     }
    },
    created() {
      this.openId = this.$route.params.openId;
      this.prj_id = this.$route.query.prjId;
      this.cfmNo = this.$route.query.cfmNo;
      
      this.getReminData();
    },
  }
</script>

<style lang="scss">
  .custom-class {
    .v-timeline-item__divider {
      min-width: $timeline-divider-width;
    }
  }
</style>