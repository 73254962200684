<template>
  <v-app>
    <v-navigation-drawer app
      v-model="drawer"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            IGS.net
          </v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
        <v-list-item-group
          color="red"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
          >
            <v-list-item-icon style="margin-top: 16px;margin-right: 0px;">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title ><a class="v-list-item theme--light text-h7" @click="goPath(item)">{{item.title}}</a></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  
    <v-app-bar app
      color="red"
      dense
      dark
    >
      <!--<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>IGS.net{{subTitle}}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
    
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    openId:'',
    drawer: false,
    group: null,
    items: [
      { title: '每日提醒', icon: 'mdi-mail', href:'/DailyRemin' },
      { title: '事件日历', icon: 'mdi-calendar', href:'/EventCalendar' },
    ],
    subTitle:''
  }),
  methods: {
    goPath: function(item){
      this.$router.push({path: item.href + '/' + this.openId});
    }
  },
  watch: {
    group () {
      this.drawer = false
    },
  },
  created() {
    this.openId = this.$route.params.openId;
    //this.subTitle = ' - ' + this.$route.meta.title;
    //console.log('this.subTitle',this.subTitle)
  }
};
</script>

<style>
.main-box{
  display: -webkit-flex; /* Safari */
  display: flex;  
  /*flex-direction: row | row-reverse | column | column-reverse;*/
  /*justify-content: flex-start | flex-end | center | space-between | space-around;*/
  /*align-items: flex-start | flex-end | center | baseline | stretch;*/
}
.fd-col{
  flex-direction: column
}
</style>