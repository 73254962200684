<template>
  <v-row dense>
    <v-col cols="12">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h7">
            每日提醒
          </v-list-item-title>
          <v-list-item-subtitle>{{getDataTime}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col 
      cols="12"
      v-for="item in reminList"
      :key="item.prj_id"
    >
      <detail-comp :detailData="item" :goDetail="true" :openId="openId"></detail-comp>
    </v-col>
  </v-row>
</template>

<script>
  import DetailComp from "../components/DetailComp";

  export default {
    name: 'DailyRemin',
    components: {
      DetailComp
    },
    data () {
      return {
        openId: '',
        reminList:[],
        getDataTime:''
      }
    },
    methods: {
      getReminData:async function(){
        let params = {
          type: 'RemindDtl'
        }
        let response = await this.$aService.pfmWxMsg(this.openId, 'RemindDtl', '');
        
        let rtnObj = response;
        let proceRtn = [];
        for(let i = 0; i<rtnObj.length; i++){
          let obj = rtnObj[i];
          let findIdx = proceRtn.findIndex(item => item.prj_id === obj.prj_id);
          if(findIdx === -1){
            let addIdx = proceRtn.push({
              prj_id:obj.prj_id,
              cfm_no:obj.cfm_no,
              prod_no:obj.prod_no,
              sub_po_no:obj.sub_po_no,
              prjName:obj.prjName,
              data:[]
            });
            proceRtn[addIdx-1].data.push(obj);
          }else{
            proceRtn[findIdx].data.push(obj)
          }
        }
        this.reminList = proceRtn;
      }
    },
    created() {
      this.openId = this.$route.params.openId;
      this.getReminData()
      this.getDataTime = this.dayjs().format("YYYY/MM/DD HH:mm:ss")
    },
  }
</script>