<template>
<v-card class="mx-auto">
  <v-card-text style="padding-left:8px;padding-right:8px">
    <div class="main-box" @click="goReminDetail(detailData.prj_id)">
      <div style="font-size: 1.25rem;font-weight: 500;word-break:break-all;width:500px;flex-grow:1" 
        class="blue--text" 
        v-html="detailData.prjName"
      >
      </div>
      <div style="font-size: 0.95rem;font-weight: 500;flex-shrink:0;align-items:flex-end" class="blue--text">
        <v-icon v-if="goDetail">mdi-chevron-right</v-icon>
      </div>
    </div>
  </v-card-text>

  <v-expansion-panels multiple>
    <v-expansion-panel v-for="itemDtl in detailData.data" :key="itemDtl.event_seq" :readonly="itemDtl.logInfo.length==0">
      <v-expansion-panel-header hide-actions style="padding-left: 8px;padding-right: 8px;" @click="getEevntLog(itemDtl)">
        <template>
          <div class="main-box fd-col">
            <div class="main-box">
              <div v-html="itemDtl.event_desc">
              </div>
              <v-spacer></v-spacer>
              <div class="text--secondary" v-html="itemDtl.sch_end_date">
              </div>
            </div>
            <div style="text-align:right;margin-top:12px">
              <span :class="itemDtl.statusColor" class="white--text" style="border:2px solid;border-radius: 4px;" v-html="itemDtl.statusDesc"></span>
            </div>
            <div style="margin-top:12px;text-align:left;" v-show="itemDtl.logInfo.length > 0">
              <span style="line-height: 1.2;font-size: .875rem;color:#1867c0" v-html="itemDtl.logInfo"></span>
              <v-icon>mdi-chevron-down</v-icon>
            </div>
          </div>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-timeline
          dense
          align-top
          class="custom-class"
        >
          <v-timeline-item 
            small
            v-for="(event,i) in itemDtl.eventLog"
            :key="i"
          >
            <div>
              <div class="font-weight-normal">
                <strong>{{event.log_by}}</strong>@{{event.log_date}}
              </div>
              <div>{{event.comment}}</div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</v-card>
</template>

<script>
  export default {
    name: 'DetailComp',
    components: {},
    data () {
      return {
        data:[]
      }
    },
    props: {
      detailData: {
        type: Object,
        default: {}
      },
      goDetail: {
        type: Boolean,
        default: false
      },
      openId: {
        type: String,
        default: ''
      }
    },
    methods: {
      init : function(){
        //console.log('this.detailData',this.detailData)
      },
      getEevntLog: async function(obj){
        if(obj.logInfo.length>0 && !obj.open){
          let params = 'eventLog='+obj.event_seq+'@'+obj.prj_id;
          let rtnObj = await this.$aService.pfmWxMsg(this.openId,params,'');
          obj.eventLog = rtnObj;
          obj.open = true;
        }else{
          obj.open = false;
        }
      },
      goReminDetail:function(prj_id){
        if(this.goDetail){
          this.$router.push({path:'/ReminDetail/'+this.openId,query:{prjId:prj_id}});
        }
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  .custom-class {
    .v-timeline-item__divider {
      min-width: $timeline-divider-width;
    }
  }
</style>