import axios from 'axios'
import sysConfig from './../config/system.config'

//const baseUrl = '../../../';

/* config axios token */
axios.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    console.log(err);
  }
);

var ajax = function (params, actionUrl, method, callBack){
  let config = {
    method: method,
    url: sysConfig.baseUrl + actionUrl
  };
  if (method == 'POST'){
    config.data = params;
  }
  if (method == 'GET'){
    config.params = params;
  }
  axios(config)
  .then(function(result){
    callBack(result.data);
  })
  .catch(function(err){    
    if (err.response.status == 401){
      callBack({'code':1});
    }
  });
};

let ajaxPostDataAsync = function(openId, action, paramAry){
  return ajaxPostDataBaseAsync(openId, 'v1/igsnet/getdata', action, paramAry);
}

let ajaxPostDataBaseAsync = function(openId, api, action, paramAry){
  let params = {
    openId: openId,
    action: action
  }
  let seqNo = 1;
  for (let key in paramAry){    
    params['prop'+seqNo] = paramAry[key];
    seqNo ++;
  }
  var p = new Promise(function(callBack, rej){
    ajax(params, api, 'POST', function(result){
      callBack(result);
    });
  });
  return p;
}

let pfmWxMsg = function(openId, msgType, date){
  let params = {
    openId: openId,
    msgType: msgType,
    date: date
  }
  var p = new Promise(function(callBack, rej){
    ajax(params, 'v1/igsnet/pfmWxMsg', 'POST', function(result){
      callBack(result);
    });
  });
  return p;
}

let ajaxGetDataAsync = function(action, paramAry){
  return ajaxGetDataBaseAsync('v1/igsnet/getdata', action, paramAry);
}

let ajaxGetDataBaseAsync = function(api){
  var p = new Promise(function(callBack, rej){
    ajax({}, api, 'GET', function(result){
      callBack(result);
    });
  });
  return p;
}

export default { pfmWxMsg, ajaxPostDataAsync, ajaxGetDataAsync }