<template>
  <v-row>
    <v-col cols="12">
      <v-sheet height="44">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            今天
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
        </v-toolbar>
      </v-sheet>
    </v-col>
    <v-col cols="12">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-color="getEventColor"
        @change="getReminData"
        @click:event="showEvent"
        :event-name="getEventName"
        :event-height="40"
      ></v-calendar>
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
        <v-toolbar
          dark
          color="red"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>事件日历 - {{dialogData.sch_end_date}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list>
          <v-row dense>
            <v-col 
              cols="12"
              v-for="item in dialogData.data"
              :key="item.prj_id"
            >
              <detail-comp :detailData="item" :goDetail="true" :openId="openId"></detail-comp>
            </v-col>
          </v-row>
        </v-list>
      </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="12" v-if="otherEvents.length>0">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">往期事件 - {{otherEvents.length}}</v-expansion-panel-header>
          <v-expansion-panel-content>
              <v-list class="transparent" style="width:100%" >
                <v-list-item 
                    v-for="item in otherEvents"
                    :key="item.sch_end_date"
                    @click="showEvent({nativeEvent:'',event:item})"
                >
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="9" style="vertical-align:center">{{ item.sch_end_date }}</v-col>
                      <v-col cols="3" class="text-right">
                        <div :class="item.color+' white--text'" style="padding:2px;width:60px;height:40px;border:2px solid;border-radius: 4px;text-align:left" v-html="item.name"></div>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                  <!--
                  <v-list-item-title>{{ item.sch_end_date }}</v-list-item-title>
                  <v-list-item-subtitle class="text-right" ><div style="width:45px;height:45px;border:2px solid;border-radius: 4px;" v-html="item.name"></div></v-list-item-subtitle>
                  -->
                </v-list-item>
              </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
  import DetailComp from "../components/DetailComp";
  export default {
    name: 'EventCalendar',
    components: {
      DetailComp
    },
    data: () => ({
      openId: '',
      dialog: false,
      dialogData: {
        sch_end_date:'',
        data:[]
      },
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],
      otherEvents:[],
    }),
    methods: {
      setToday () {
        this.value = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      getEventName (event){
        return (event.input.name)
      },
      getEventColor (event) {
        return event.color
      },
      showEvent ({ nativeEvent, event }) {
        this.dialogData.sch_end_date = event.sch_end_date;

        let rtnObj = event.data;
        let proceRtn = [];
        for(let i = 0; i<rtnObj.length; i++){
          let obj = rtnObj[i];
          let findIdx = proceRtn.findIndex(item => item.prj_id === obj.prj_id);
          if(findIdx === -1){
            let addIdx = proceRtn.push({
              prj_id:obj.prj_id,
              cfm_no:obj.cfm_no,
              prod_no:obj.prod_no,
              sub_po_no:obj.sub_po_no,
              prjName:obj.prjName,
              data:[]
            });
            proceRtn[addIdx-1].data.push(obj);
          }else{
            proceRtn[findIdx].data.push(obj)
          }
        }

        this.dialogData.data = proceRtn;

        this.dialog = true;
      },
      getReminData:async function({ start, end }){
        this.events = [];
        this.otherEvents = [];
        let proceRtn = []
        let rtnObj = await this.$aService.pfmWxMsg(this.openId,'Monthly',this.dayjs(`${start.date}`).format('YYYY/MM/DD'));

        for(let i = 0; i<rtnObj.length; i++){
          let obj = rtnObj[i];
          let findIdx = proceRtn.findIndex(item => item.sch_end_date === obj.sch_end_date);
          if(findIdx === -1){
            let addIdx = proceRtn.push({
              name:obj.dailyInfo,
              start:new Date(obj.sch_end_date+' 00:00:00'),
              end:new Date(obj.sch_end_date+' 23:59:59'),
              color:obj.dailyColor,
              timed: false,
              data:[],
              sch_end_date:obj.sch_end_date,
              status:obj.status,
              prj_id:obj.prj_id,
              prjName:obj.prjName,
            });
            proceRtn[addIdx-1].data.push(obj);
          }else{
            proceRtn[findIdx].data.push(obj)
          }
        }

        for(let i = 0; i<proceRtn.length; i++){
          let obj = proceRtn[i];
          if(this.dayjs(`${start.date}`).format('YYYY/MM') != this.dayjs(`${obj.sch_end_date}`).format('YYYY/MM')){
            this.otherEvents.push(obj)
          }
        }
        let self = this;
        this.$nextTick(()=> {
          for (let obj of proceRtn){
            self.events.push(obj);
          }
        })
      },
      goReminDetail:function(prj_id){
        this.$router.push({path:'/ReminDetail/'+this.openId,query:{prjId:prj_id}});
      }
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    created() {
      this.openId = this.$route.params.openId;
    }
  }
</script>

<style lang="scss" scoped>
</style>