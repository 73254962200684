<template>
  <h3>IGS.net</h3>
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',
    components: {
      HelloWorld,
    },
    data: () => ({
    }),
    methods: {
      
    }
  }
</script>
