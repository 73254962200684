import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import EventCalendar from '../components/EventCalendar.vue'
import DailyRemin from '../components/DailyRemin.vue'
import ReminDetail from '../components/ReminDetail.vue'
import EventList from '../components/EventList.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/:openId',
    name: 'Home',
    meta:{
      title:'主页'
    },
    component: Home
    
  },
  {
    path: '/EventCalendar/:openId',
    name: 'EventCalendar',
    meta:{
      title:'事件日历'
    },
    component: EventCalendar
  },
  {
    path: '/DailyRemin/:openId',
    name: 'DailyRemin',
    meta:{
      title:'每日提醒'
    },
    component: DailyRemin
  },
  {
    path: '/ReminDetail/:openId',
    name: 'ReminDetail',
    meta:{
      title:'定单详细'
    },
    component: ReminDetail
  },
  {
    path: '/EventList/:openId',
    name: 'EventList',
    meta:{
      title:'事件定单'
    },
    component: EventList
  },
  {
    path: '/about/:openId',
    name: 'About',
    meta:{
      title:'关于'
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
