<template>
  <v-row dense>
    <v-col cols="12">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h7">
            {{eventHdr.event_no}}
          </v-list-item-title>
          <v-list-item-subtitle>{{eventHdr.event_desc}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col 
      cols="12"
      v-for="item in eventList"
      :key="item.prj_id"
    >
      <detail-comp :detailData="item" :goDetail="true" :openId="openId"></detail-comp>
    </v-col>
  </v-row>
  
</template>

<script>
  import DetailComp from "../components/DetailComp";
  export default {
    name: 'EventList',
    components: {
      DetailComp
    },
    data () {
      return {
        openId: '',
        eventNo:'',
        eventHdr:{
          event_no: '',
          event_desc: ''
        },
        eventList:[]
      }
    },
    methods: {
      getEventData:async function(){

        this.eventList =[];
        let params = 'eventDtl=' + this.eventNo;
        let response = await this.$aService.pfmWxMsg(this.openId, params, '');
        
        if(response && response.length>0){
          this.eventHdr.event_no = response[0].event_no
          this.eventHdr.event_desc = response[0].event_desc

          let proceRtn = [];
          for(let i = 0; i<response.length; i++){
            let obj = response[i];
            obj.eventLog = [];
            let findIdx = proceRtn.findIndex(item => item.prj_id === obj.prj_id);
            if(findIdx === -1){
              let addIdx = proceRtn.push({
                prj_id:obj.prj_id,
                cfm_no:obj.cfm_no,
                prod_no:obj.prod_no,
                sub_po_no:obj.sub_po_no,
                prjName:obj.prjName,
                data:[]
              });
              proceRtn[addIdx-1].data.push(obj);
            }else{
              proceRtn[findIdx].data.push(obj)
            }
          }

          this.eventList = proceRtn;
        }

        
      },
      goReminDetail:function(prj_id){
        this.$router.push({path:'/ReminDetail/'+this.openId,query:{prjId:prj_id}});
      }
    },
    created() {
      this.openId = this.$route.params.openId;
      this.eventNo = this.$route.query.eventNo;
      this.getEventData();
    },
  }
</script>